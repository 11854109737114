import * as React from "react";
import { Typography, Box } from "@mui/material";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Iconify from "./Iconify";
import { BorderTop } from "@mui/icons-material";
import moment from "moment-timezone";
export default function CustomTimeline(props) {
  const data = props.data;
  return (
    <Box
      style={{
        display: "flex",
        paddingLeft: 10,
        height: "100vh",
        padding: 2,
        // backgroundColor: "#f90000",
        borderRadius: 2,
      }}
    >
      <Timeline
        sx={{
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
          },
        }}
      >
        {data?.steps?.map((val, index) => {
          console.log("🚀 ~ {data?.steps?.map ~ val:", val);
          // const date =
          return (
            <TimelineItem
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
              key={val.id}
            >
              <TimelineSeparator>
                <TimelineDot
                  style={{
                    height: 24,
                    width: 24,
                    marginBottom: 5,
                    borderWidth: 3,
                    backgroundColor: "#FFFFFF",
                    borderColor: val?.isCompleted === 1 ? "#ED1B24" : "grey",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 2,
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      borderColor: val?.isCompleted === 1 ? "#ED1B24" : "grey",
                      borderWidth: 2,
                      justifyContent: "center",
                      alignItems: "center",
                      height: 25,
                      width: 25,
                      backgroundColor:
                        val?.isCompleted === 1 ? "#ED1B24" : "#FFFFFF",
                      borderRadius: 100,
                    }}
                  >
                    {val?.isCompleted === 1 ? (
                      <Iconify
                        style={{ fontSize: 18, color: "white" }}
                        icon="ic:round-check"
                      />
                    ) : (
                      <div style={{ height: 25, width: 25 }} />
                    )}
                  </Box>
                </TimelineDot>
                <TimelineConnector
                  sx={{
                    bgcolor: "transparent",
                    height: "auto",
                    width: 0,
                    display: "flex",
                    flexDirection: "column",
                    marginRight: 0.3,
                  }}
                >
                  {data?.steps.length != index + 1 && (
                    <div
                      style={{
                        height: 50,
                        flex: 1,
                        // borderWidth: 1.5,
                        border:
                          data?.steps[index + 1]?.isCompleted == 1
                            ? "2px dashed red"
                            : "2px dashed grey",
                        // borderColor:
                        // index == data.steps.length - 1 ? "#FFFFFF" : "#ED1B24",
                      }}
                    />
                  )}
                </TimelineConnector>
              </TimelineSeparator>
              <TimelineContent
                style={{ height: 50, marginBottom: 10 }}
                sx={{ py: "10px", px: 2 }}
              >
                <Typography
                  variant={"body1"}
                  component="span"
                  style={{ fontWeight: 600 }}
                >
                  {val?.name}
                </Typography>

                <>
                  {val.updated_at && val?.isCompleted === 1 && (
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: {
                          xs: "0.55rem",
                          sm: "0.575rem",
                          md: "0.6rem",
                        },
                      }}
                    >
                      {moment
                        .utc(val?.updated_at)
                        .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
                        .format("DD-MMM-YYYY h:mm A")}
                      {/* {moment(val?.updated_at).format("DD-MMM-YYYY, h:mm A")} */}
                    </Typography>
                  )}

                  {val?.notes ? (
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: {
                          xs: "0.55rem",
                          sm: "0.575rem",
                          md: "0.6rem",
                        },
                        lineHeight: { xs: "1.3", sm: "1.3", md: "1.2" },
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        WebkitLineClamp: 2,
                        height: 24,
                      }}
                    >
                      {val?.notes}
                    </Typography>
                  ) : (
                    <div style={{ height: 35 }} />
                  )}
                </>
              </TimelineContent>
            </TimelineItem>
          );
        })}
      </Timeline>
    </Box>
  );
}
