const mainUrl = "https://monprogress.dotserviz.co/api/";
// const mainUrl = "https:monprogress.samtwireless.com/public/api/";

const api = {
  get_service_detail_for_customer: `${mainUrl}get_service_detail_for_customer`,
  get_service_message: `${mainUrl}get_service_message_for_web`,
  get_service_document_for_web: `${mainUrl}get_service_document_for_web`,
  save_service_document_for_web: `${mainUrl}save_service_document_for_web`,
  delete_service_document_for_web: `${mainUrl}delete_service_document_for_web`,
  verify_web_otp: `${mainUrl}verify_web_otp`,
};

export default api;
