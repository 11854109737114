import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import moment from "moment";
import api from "../Constants/Api";

export default function Messagebox({ messaegeid }) {
  const [data, setdata] = useState([]);
  const [error, seterror] = useState([]);

  useEffect(() => {
    handleMessage();
  }, [messaegeid]);

  const handleMessage = () => {
    const params = new URLSearchParams();
    params.append("customer_service_id", messaegeid);
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };
    const url = `${api.get_service_message}?${params.toString()}`;
    fetch(url, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        const result = JSON.parse(result_);
        if (result.success === true) {
          setdata(result?.data);
        } else {
          seterror(result.message);
          console.log("error", result.message);
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => {});
  };
  return (
    <Box
      style={{
        paddingLeft: 10,
        height: "100vh",
        padding: 2,
        borderRadius: 2,
        overflow: "auto",
      }}
    >
      <Typography
        variant="h6"
        gutterBottom
        color={"#f90000"}
        textAlign={"center"}
        sx={{ mt: 1 }}
      >
        Messages
      </Typography>
      {data.map((message) => (
        <Box
          key={message.id}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            //   message.sender === "user" ? "flex-end" : "flex-start",
            mb: 1,
            paddingLeft: 1,
          }}
        >
          <Box
            sx={{
              maxWidth: "90%",
              padding: 2,
              backgroundColor: "#fff",
              borderRadius: 2,
              boxShadow: 1,
              alignItems: "center",
            }}
          >
            <Typography variant="body2" color="text.primary">
              {message.message}
            </Typography>{" "}
            <Typography
              pt={0.5}
              variant="body2"
              color="text.primary"
              sx={{ textAlign: "right", fontSize: 12 }}
            >
              {message.message_time}
            </Typography>
          </Box>
        </Box>
      ))}

      {data?.length === 0 && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
          }}
        >
          <Typography color={"#ccc"}>{error}</Typography>
        </Box>
      )}
    </Box>
  );
}
