import React, { useState, useEffect } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  DirectionsRenderer,
  Polyline,
  Marker,
  OverlayView,
} from "@react-google-maps/api";
import steps_icon from "../assets/icons/steps_icon.png";
import start from "../assets/icons/start.png";
import end from "../assets/icons/end.png";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import moment from "moment-timezone";

const containerStyle = {
  width: "100%", // 50% of the viewport width
  height: "100%", // Full viewport height
};

function Maps(props) {
  const data = props.data;

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCfkkFnJ7ixENdtACx3-Q5Mewh3wftkCo8", // Replace with your API key
  });

  const [map, setMap] = useState(null);
  const [directions, setDirections] = useState(null);
  const [showEnd, setShowEnd] = useState(true);
  const [routePath, setRoutePath] = useState([]);
  const [waypoints, setWaypoints] = useState([]);
  const [pickup_lat, set_pickup_lat] = useState(null);
  const [pickup_lon, set_pickup_lon] = useState(null);
  const [dropoff_lat, set_dropoff_lat] = useState(null);
  const [dropoff_lon, set_dropoff_lon] = useState(null);
  const [center, setCenter] = useState({ lat: 0, lng: 0 });

  useEffect(() => {
    if (data) {
      if (data.pickup_lat && data.pickup_lon) {
        set_pickup_lat(parseFloat(data.pickup_lat));
        set_pickup_lon(parseFloat(data.pickup_lon));
      } else if (data.steps && data.steps.length > 0) {
        set_pickup_lat(parseFloat(data.steps[0].lat));
        set_pickup_lon(parseFloat(data.steps[0].lon));
      }

      let dropStep = null;
      if (data.steps && data.steps.length > 0) {
        dropStep = data.steps.filter((step) => {
          return step.isCompleted === 1;
        });
      }

      if (data.dropoff_lat && data.dropoff_lon) {
        set_dropoff_lat(parseFloat(data.dropoff_lat));
        set_dropoff_lon(parseFloat(data.dropoff_lon));
      } else if (dropStep?.length > 0) {
        set_dropoff_lat(parseFloat(dropStep[dropStep.length - 1].lat));
        set_dropoff_lon(parseFloat(dropStep[dropStep.length - 1].lon));
      } else if (data.steps && data.steps.length > 0) {
        set_dropoff_lat(parseFloat(data.steps[data.steps.length - 1].lat));
        set_dropoff_lon(parseFloat(data.steps[data.steps.length - 1].lon));
      }

      setCenter({
        lat: data.pickup_lat
          ? parseFloat(data.pickup_lat)
          : data.steps && data.steps.length > 0
          ? parseFloat(data.steps[0].lat)
          : 0,
        lng: data.pickup_lon
          ? parseFloat(data.pickup_lon)
          : data.steps && data.steps.length > 0
          ? parseFloat(data.steps[0].lon)
          : 0,
      });
    }
  }, [data]);

  useEffect(() => {
    let dropStep = null;
    if (data.steps && data.steps.length > 0) {
      dropStep = data.steps.filter((step) => {
        return step.isCompleted === 1;
      });
      if (dropStep.length > 0 && !data.dropoff_lat && !data.dropoff_lon) {
        setShowEnd(false);
      } else if (dropStep.length > 0 && data.dropoff_lat && data.dropoff_lon) {
        setShowEnd(true);
      }
    }
  }, [data]);

  const directionsCallback = (response) => {
    if (response !== null && response.status === "OK") {
      setDirections(response);

      const route = response.routes[0].overview_path;
      setRoutePath(
        route.map((point) => ({ lat: point.lat(), lng: point.lng() }))
      );

      const wp = response.routes[0].legs;
      setWaypoints(
        wp.map((point) => ({
          lat: point.start_location.lat(),
          lng: point.start_location.lng(),
        }))
      );
    } else {
      console.log(`Error fetching directions ${response}`);
    }
  };

  useEffect(() => {
    if (map) {
      const directionsService = new window.google.maps.DirectionsService();

      // Filter the steps to include only those where isCompleted is 1 or lat and lng are present
      let temparrayforwaypoints = data.steps?.filter(
        (item) => item.isCompleted === 1 && item.lat && item.lon
      );

      // Map the filtered steps to waypoints array
      const waypointsData = temparrayforwaypoints?.map((item) => ({
        location: {
          lat: parseFloat(item.lat),
          lng: parseFloat(item.lon),
        },
      }));

      directionsService.route(
        {
          origin: { lat: pickup_lat, lng: pickup_lon },
          destination: { lat: dropoff_lat, lng: dropoff_lon },
          travelMode: window.google.maps.TravelMode.DRIVING,
          waypoints: waypointsData,
          optimizeWaypoints: true,
        },
        directionsCallback
      );
    } else {
      // No valid waypoints, do not create a route
      console.log("No valid waypoints found. Not creating a route.");
    }
  }, [map, pickup_lat, pickup_lon, dropoff_lat, dropoff_lon, data.steps]);

  const onLoad = (mapInstance) => {
    mapInstance.setCenter(center); // Replace `center` with your desired center coordinates
    mapInstance.setZoom(17); // Set your desired zoom level
    setMap(mapInstance);
  };
  const onUnmount = () => {
    setMap(null);
  };

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {routePath.length > 0 && (
        <>
          <Marker
            position={{ lat: pickup_lat, lng: pickup_lon }}
            icon={{
              url: start, // Pickup icon
              scaledSize: new window.google.maps.Size(80, 100),
            }}
          />
          <Polyline
            path={routePath}
            options={{
              strokeColor: "#f90001",
              strokeOpacity: 1.0,
              strokeWeight: 5,
            }}
          />

          {data.steps.some((step) => step.isCompleted === 1) && (
            <>
              {waypoints.map((waypoint, index) => (
                <>
                  {data.steps[index - 1]?.name && (
                    <Marker
                      key={index}
                      position={{ lat: waypoint.lat, lng: waypoint.lng }}
                      icon={{
                        url: steps_icon,
                        scaledSize: new window.google.maps.Size(47, 47),
                        anchor: new window.google.maps.Point(26, 26),
                      }}
                    />
                  )}
                  {data.steps[index - 1]?.name && (
                    <OverlayView
                      position={{ lat: waypoint.lat, lng: waypoint.lng }}
                      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                    >
                      <div
                        style={{
                          height: 45,
                          width: 140,
                          backgroundColor: "#f90001",
                          borderRadius: "5px",
                          color: "white",
                          textAlign: "center",
                          justifyContent: "center",
                          alignItems: "center",
                          display: "flex",
                          flexDirection: "column",
                          marginTop: -24,
                          marginLeft: 25,
                          paddingBottom: 0,
                          paddingTop: 0,
                        }}
                      >
                        <p style={{ padding: 0, margin: 0 }}>
                          {data.steps[index - 1]?.name}
                        </p>
                        <p style={{ padding: 0, margin: 0 }}>
                          {/* {moment(data.steps[index - 1]?.updated_at).format('DD-MMM-YYYY, h:mm A')} */}
                          {moment
                        .utc(data.steps[index - 1]?.updated_at)
                        .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
                        .format("DD-MMM-YYYY h:mm A")}
                          {/* {moment
                            .utc(data.steps[index - 1]?.updated_at)
                            .tz("Etc/GMT-5")
                            .format("DD-MMM-YYYY h:mm A")} */}
                        </p>
                      </div>
                    </OverlayView>
                  )}
                </>
              ))}
            </>
          )}
          {showEnd && (
            <Marker
              position={{ lat: dropoff_lat, lng: dropoff_lon }}
              icon={{
                url: end, // Dropoff icon
                scaledSize: new window.google.maps.Size(80, 100),
              }}
            />
          )}
        </>
      )}
    </GoogleMap>
  ) : (
    <p>Loading...</p>
  );
}

export default React.memo(Maps);
